import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Logo from "../snippets/Logo"
import MobileNav from "../snippets/MobileNav"
import MainNav from "../snippets/MainNav"
import MobileBottomBar from "../snippets/MobileBottomBar"
import ShoppingCart from "../snippets/ShoppingCart"
import NavSidebar from "../snippets/NavSidebar"
import SearchForm from "../snippets/SearchForm"
import ScriptTag from 'react-script-tag';
import {Snowfall} from "react-snowfall";

export default (props) => {
  // function heartBeat() {
  //   imageHeartBeat().then(response => response);
  //   productHeartBeat().then(response => response);
  //   productCategoryHeartBeat().then(response => response);
  //   userHeartBeat().then(response => response);
  //   orderHeartBeat().then(response => response);
  // }
  //
  // function checkHeartBeat(){
  //   heartBeat();
  //   // setInterval(heartBeat, 45000);
  // }
  //
  // checkHeartBeat();

    const [curMonth, setCurMonth] = useState("")


  useEffect(()=>{
      const currentMonth = new Date().toLocaleString('default', { month: 'long' });
      setCurMonth(currentMonth);
  },[]);

  return (
      <div>
        <ScriptTag src="/js/menu.js"/>
        <header className="c-header js-header">
          <div className="c-header__position">
            <div className="o-wrapper o-wrapper--full-width">
              <div className="o-flex o-flex--align-items-center">
                  {curMonth === 'December' && <Snowfall
                      color="#FF0000"
                      snowflakeCount={35}
                      style={{
                          position: 'fixed',
                          width: '100vw',
                          height: '100vh',
                          top: 0,
                          left: 0,
                          pointerEvents: 'none', // So it doesn't block user interactions
                      }}
                  />}
                <MobileNav/>
                <Logo/>
                <MainNav/>
                <div className="c-header-desktop__right o-flex o-flex--align-items-center">
                  <div className="c-header-search u-hidden">
                    <i className="e-icons e-icons--icon-magnifier js-open-search"></i>
                  </div>
                  <div className="c-header-account">
                    <Link to="/account/login">
                      <i aria-hidden="true" className="e-icons e-icons--icon-user"/>
                    </Link>
                  </div>
                  {/*<div className="c-header-wishlist">*/}
                  {/*  <Link to="/pages/wishlist">*/}
                  {/*    <i aria-hidden="true" className="e-icons e-icons--icon-heart"/>*/}
                  {/*    <span className="c-header-wishlist__counter js-wishlist-counter"/>*/}
                  {/*  </Link>*/}
                  {/*</div>*/}
                  <ShoppingCart/>
                  <div className="c-header-nav-sidebar">
                    <i className="e-icons e-icons--icon-menu js-open-nav-sidebar"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="e-close-background js-close-background"></div>
        <NavSidebar/>
        <SearchForm/>
        <MobileBottomBar/>
      </div>
  )
}